@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');


.container-slider *{
    font-family: 'Cormorant Garamond', serif;
    box-sizing: border-box;
    z-index: 1;
}

.container-slider h2{
    font-size: 3rem;
    font-weight: 400;
    line-height: 3rem;
    margin: .5rem;
}

.container-slider p{

    font-size: 1.5rem;
    margin: .5rem;
}

.container-slider button{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    background-color: transparent;
    color: rgb(68, 68, 68);
    border: 1px solid rgb(173, 173, 173);
    padding: .5rem 1rem;
    transition: .4s;
    margin: 1rem .5rem;
}

.container-slider button:hover{
    background-color: black;
    border: 1px solid rgb(34, 34, 34);
    color: white;
}

.radio-slider{
    display: flex;
    gap: .5rem;
}

.container-slider{
    box-sizing: border-box;
    display: flex;
    gap: 2rem;
    padding: 32px;
    justify-content: space-around;
    width: 100vw;
    align-items: center;
    text-align: left;
}

.hero-image{
    width: 880px;
    height: 520px;
    box-shadow: inset 10 10  white;
    background-size: cover;
    background-position: center;
    outline: 1px solid #b1b1b1;
    outline-offset: .5rem;
}

.hero-content{
    width: 400px;
    
}

.slider-controls{
    display: flex;
    justify-content: center;
    gap: 32px;
    align-items: center;
    padding: 1rem 0rem;
}

.slider-controls svg{
    color: #6f6f6f;
    cursor: pointer;
}


.counter{
}

.counter *{
    display: inline;
    background-color: transparent;
    border: none;
    font-size: 24px;
    padding: 0 .5rem; 
    width: 3rem;
}



.product-content>button{
    width: 95%;
    text-align: center;
    margin: 1rem;
    background-color: black;
    color: white;
    font-size: 32px;
    font-weight: 100;
    font-family: "Roboto";
    border: 1px solid black;
    padding: .5rem 0;
    cursor: pointer;

}

@media (width <= 900px){
    .container-slider{
        gap: 1rem;
        flex-direction: column;
        padding-block: 0 ;
    }
    .hero-image{
        width: 100vw;
        height: 40vh;
    }
    .hero-content{
        text-align: center;
        max-width: 100vw;
    }
}


