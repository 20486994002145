@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');


.home-grids h2{
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-size: 58px;
    font-weight: 400;
    margin-top: 0rem;
    margin-bottom: 0;
    padding-top: 32px;
}

.grid-container {
    max-width: 100vw;
    box-sizing: border-box;
    width: 70rem;
    aspect-ratio: 2/1;
    margin: auto;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    padding: 32px;
    grid-auto-flow: dense;
}

.grid-title-image{
    grid-column-end: span 2;
    grid-row-end: span 2;
    background-size: cover;
    background-position: center;
    margin: .5rem;
    outline: 1px solid #b1b1b1;
    outline-offset: .5rem;
}

.even .grid-title-image{
    
    grid-column-start: 3;
}

.product-card-grid{
    position: relative;
    outline-offset: .5rem;
    margin: .5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.product-card-grid img{
    width: calc(100% - 1rem);
    aspect-ratio: 1;
    object-fit: contain;
    z-index: 4;
    border: 1px solid var(--color-black);
    margin-inline: .5rem;
}
.product-card-grid .grid-name{
    background-color: var(--color-background-nav);
    display: inline-block;
    position: absolute;
    width: 100%;
    bottom: 0;
    border: 1px solid var(--color-black);
    text-align: center;
    padding: .5rem 0;
    z-index: 5;

    
}


@media (width <= 768px){
    .grid-container{
        width: 100vw;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;

    }
    .grid-title-image{
        display: none;
        width: 100%;
        height: 200px;
        
    }
}


