@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');


:root{
  --color-black: #343434;
  --color-light-grey: #c4c4c4;
  --color-background-nav: #fff5f2;
  --color-background-hover: #f6edea;
  --color-background-drawers: #fff5f2;
  --color-accent-one: #fae9e4;
  --color-accent-two: #ffeee9;
  --background-color: #ffffff;
  background-color: #ffffff;

}

*{
  font-family: 'Roboto', sans-serif;
  color: var(--color-black);
  box-sizing: border-box;
  overflow-wrap: break-word;
  margin: 0;
  padding: 0;
  list-style: none;
}
 li, ul{
  display: inline-block;
 }

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

svg{
  cursor: pointer;
}

button{
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a{
  text-decoration: none;
  color: black;
}

.icon{
  justify-self: center;
}

.rating{
  display: inline-flex;
  align-items: center;
  position: relative;
  top: .25rem;
  & svg{
    fill: var(--color-black);
  }
}

.nothing{
  margin: auto;
  border: 1px solid var(--color-light-grey);
  color: var(--color-light-grey);
  padding: 5rem 1rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 500px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.clr-red{
  fill: rgb(255, 98, 98);
}
details {
  width: 90%;
  margin: 0 auto ;
  font-weight: 300;
  margin-bottom: .5rem;
  border: 1px solid var(--color-light-grey);
  border-radius: 5px;
  overflow: hidden;
  padding: 1rem;
}

summary {
  padding: .25rem;
  display: block;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

summary:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent var(--color-black);
  position: absolute;
  top: .5rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .25s transform ease;
}

details[open] > summary:before {
  transform: rotate(90deg);
}
details[open] {
border-color: var(--color-black);
}

.off-click-handler{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  background-color: rgba(219, 182, 141, .3);
  top: 0;
  left: 0;
}

.modal{
  z-index: 999;
  background-color: white;
  width: min(90vw, 30rem);
  height: min(90vh, 20rem);
  position: fixed;
  top: 50vh;
  left: 50vw;
  translate: -50% -50%;
  border: 1px solid var(--color-black);
  padding: 1rem;
  & input[type="range"]{
    width: 10rem;
    padding: 0;
  }

  & textarea{
    height: calc(100% - 8rem) ;
    margin-top: 0rem;
    display: block;
    width: 100%;
    padding: 1rem;
    flex-wrap: wrap;
  }



  & button{
    width: 100%;
    font-size: 2rem;
    margin: .5rem auto;
    border: 1px solid var(--color-black);
  }
}

.modal-off{
  z-index: 950;
}

.thumbnail-for-list{
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
  display: none;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  display: none;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}