.product-card{
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    text-align: left;
    max-width: 95vw;
    width: 29%;
    border: 1px solid transparent;
    padding: 1rem 0rem;
    margin: .5rem;
    z-index: 1;
    align-self: flex-start;
    cursor: pointer;
}

.product-card .rating{
    float: right;
    padding-right: 1rem;
    padding-bottom: 0;
}

.product-card .container{
    padding-left: 1rem;
}

.product-card .wishlist-icon{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.product-card:hover{
    border: 1px solid rgb(217, 217, 217);
}
.oos-card, .oos-card:hover{
    border: 1px solid rgb(255, 225, 225);
}

.btn-prime.btn-disabled.oos-banner{
    border-block:1px solid rgb(255, 225, 225) ;
    text-align: center;
    border-inline: none;
    width: 100%;
    position: relative;
    left: -.85rem;
    overflow: hidden;

    
}

.btn-prime.btn-disabled.oos-banner h5{
    font-family: 'Courier New', Courier, monospace;
    white-space: nowrap;
    animation: 10s infinite normal slide-right;
    color: rgb(255, 151, 151);
    height: 100%;
    margin: auto;
    transition-timing-function: linear;
}

@keyframes slide-right {
    from {
      transform: translateX(-106%);
    }
    to {
      transform: translateX(-5%);
    }
  }


.product-card>.img-card{
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
}

.product-card h4{
    font-family: 'Courier New', Courier, monospace ;
    font-size: 1.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    border-bottom: 1px solid rgb(213, 213, 213);
    margin-right: 1rem;

}

.product-card h5:first-of-type{
    color: black;

}

.product-card h5{
    font-weight: 400;
    color: rgb(37, 37, 37);
}

.btn-prime{
    display: inline-block;
    width: 90%;
    background-color: white;
    color: #343434;
    font-size: 1rem;
    padding: .5rem;
    border: 1px solid rgb(192, 192, 192);
    margin: 0 5%;
    transition: .6s;
}

.btn-prime.btn-disabled{
    background-color: transparent;
    &:hover{
        background-color: transparent;
        border-inline: none;
    }
}

.btn-prime:hover{
    color: white;
    background-color: #343434;
    border: 1px solid #343434;
}

.product-card .category{
    border: 1px solid rgb(223, 223, 223);
    padding: .25rem .5rem;
    background-color: white;
    position: absolute;
    font-weight: 300;
    top: 1rem;
    left: 1rem;
}

.strikethrough{
    text-decoration: line-through;
    color: rgb(150, 150, 150);
    padding-right: .5rem;
}

.wishlist-icon.filled{
    fill: #474747;
}
 


@media (width<1024px){

    .product-card{
        min-width: 240px;
        width: 30%;
        margin: 1rem .25rem;
        max-width: 95vw;
    }
}

@media (width<768px){
    .product-card{
        min-width: 240px;
        max-width: 95vw;
    }
}


