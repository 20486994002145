

.cart-container ,.wishlist-container, .search-container{
    background-color: var(--color-background-nav);
    width: min(30rem, 95vw);
    z-index: 901;
    position: fixed;
    top: 0;
    display: inline-flex;
    right: max(-30rem, -95vw);;
    height: 100vh;
    justify-content: flex-end;
    transition: .3s ease-in-out;
}

.cart-container.active, .wishlist-container.active, .search-container.active{
    right: 0;
}


.cart-actual, .wishlist-actual, .search-actual{
    width: 34rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    border-left: 1px solid var(--color-light-grey);
}

.cart-actual header, .wishlist-actual header, .search-actual header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    border-bottom: 1px solid rgb(202, 202, 202);
    margin-bottom: 1rem;
    & input{
        font-size: 1rem;
        padding: .2rem;
        font-weight: 300;
        background-color: transparent;
        margin: .55rem;
        border: none;
        flex-grow: 1;
    }
}

.search-actual header{
    border: 1px solid var(--color-light-grey);
    border-radius: 2rem;
    margin: .5rem;
    padding-inline: .5rem;
    min-height: 2.75rem;


}


.btn-sec{
    background-color: white;
    color: black;
}


.cart-actual h4, .wishlist-actual h4{
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    padding: .5rem 0;
    font-weight: 300;
}

.cart-actual .icon:hover{
    cursor: pointer;
}

.cart-actual footer, .wishlist-actual footer{
    margin-bottom: 2rem;
    border-top: 1px solid rgb(79, 79, 79);
}

footer button{
    width: 95%;
    margin: .5rem;
    background-color: black;
    color: white;
    font-size: 2rem;
    font-weight: 100;
    font-family: "Roboto";
    border: 1px solid black;
    padding: .5rem 0;
    cursor: pointer;
}

.cart-products, .wishlist-products, .search-products, .recomended-products{
    overflow: scroll;
    flex-grow: 1;
    & .nothing{
        
        margin-block: 50%;
    }
}



