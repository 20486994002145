.profile-container{
    display: inline-flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: max(-30rem, -90vw);
    width: min(30rem, 90vw);
    z-index: 902;
    height: 100vh;
    background-color: var(--color-background-drawers);
    border-left: 1px solid var(--color-light-grey);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: .3s ease-in-out;
}

.profile-container.active{
    right: 0
}

.profile-container header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-light-grey);
    padding: 0 2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 904;
    background-color: var(--color-background-nav);
}

.profile-container header h2{
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    padding: .5rem 0;
    font-weight: 300;
}

.user-container{
    padding: .5rem;
}

.user{
    margin: 2rem;
     text-align: center;
    & h2{
        font-family: 'Cormorant Garamond';
        font-weight: 600;
        margin: .5rem;
    }

    & h4{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        padding: 0 .25rem;
        width: 50%;
        margin: .5rem auto;
        border-radius: 5px;
        font-weight: 300;
    }

    & details{
        width: 50%;
        padding: .25rem;
    }
    & summary{
        font-weight: 300;
        text-align: left;
    }
    
    & button{
        margin: .25rem;
        width: 50%;
        padding: .5rem;
        border: 1px solid var(--color-black);
        transition: .3s;
        &:hover{
            background-color: black;
            color: white;
        }
    }
}

.btn-submit{
    width: 100%;
    border: 1px solid black;
    padding: .5rem;
    color: white;
    background-color: var(--color-black)

}

.container-filp{
    flex-grow: 1;
    align-self: center;
    position: relative;
    text-align: center;
    width: 100%;
    padding: .5rem;
    transform-style: preserve-3d;
    margin: .1rem .5rem;
    min-height: 35rem;
}


.login-form *, .signup-form *{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

details.details-form label{
    font-size: .75rem;
    display: inline;
    position: relative;
    margin-bottom: 2rem;
}

details.details-form input{
    margin-bottom: .5rem;
}


.login-form, .signup-form{
    outline: 1px solid var(--color-black);
    border: 1px solid var(--color-light-grey);
    outline-offset: -.5rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    margin: auto;
    justify-self: center;
    flex-direction: column;
    gap: 1rem;
    align-items: center; 
    width: 100%;
    height: 100%;
    padding: 2rem 0;
    background-color: var(--color-background-drawers);
}


.login-form{
    transition: 1s ease-in-out;
    background-color: var(--color-accent-two);
}

.flipped .login-form{
    transform: rotateY(-180deg);
}

.signup-form{
    transform: rotateY(180deg);
    transition: 1s ease-in-out;
    position: absolute;
    text-align: center;
    top: .5rem;
    background-color: var(--color-accent-one);
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);;
}

.flipped .signup-form{
    transform: rotateY(0deg);
}

.login-form h3, .signup-form h3{
    font-family: 'Cormorant Garamond', serif;
    font-size: 2rem;
    font-weight: 400;
    transform: translateZ(100px);  
}

.login-form *, .signup-form *{
    transform: translateZ(70px);
}

.login-form button, .signup-form button{
    transform: translateZ(80px);
}

.container-filp label{
    width: 75%;
    display: block;
    font-size: .75rem;
    text-align: left;
    font-weight: 400;
}

label input{
    display: block;
    width: 100%;
    padding: .5rem;
    margin: .25rem 0 0 0;
    background-color: transparent;
    border: 1px solid var(--color-light-grey);
}

:focus-visible{
    outline: none;
    border: 1px solid var(--color-black);
}

.login-form button, .signup-form button{
    width: 75%;
    padding: .75rem;
    color: white;
    background-color: var(--color-black);
}

.login-form button.btn-secondary, .signup-form button.btn-secondary{
    width: 75%;
    padding: .75rem;
    color: var(--color-black);
    border: 1px solid var(--color-light-grey);
    background-color: white;
}

.address input{
    margin-bottom: 1rem;
}

label.wrapper-l{
    width: 100%;
}

label.wrapper-l input{
    width: 49%;
    display: inline;
    margin-bottom: .25rem;
    &:first-child{
        margin-right: 2%;
    }


}

.review-btn{
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: center;
    background-color: rgb(208, 255, 208);
    border: 1px solid var(--color-light-grey);
    border-radius: .3rem;
    color: black;
    padding: .25rem;
    font-size: .75rem;
}


form.form-edit label input{
    padding: .25rem;
    margin: .25rem;
    width: 47%;
    display: inline;
}

form.form-edit{
    border-bottom: 1px solid var(--color-black);
    margin-block: 1rem;
}

.btn-50{
    width: 47%;
    border: 1px solid black;
    margin: .5rem .25rem;
    padding: .25rem;
    &.red{
        background-color: rgb(255, 188, 188);
    }
}

@media (height<=48rem) {
    .signup-form input{
    padding: .25rem;
}
}


