.checkout-page{
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    max-width: 78rem;
    margin: auto;
    padding: 1rem;
justify-content: center;



    & section{
        border: 1px solid var(--color-light-grey);
        flex-grow: 1;
        width: calc(33.33% - 3rem);
        height: 600px;
        overflow: scroll;
    }

    & header{
        position: sticky;
        top:0;
        border-bottom: 1px solid var(--color-light-grey);
        padding: 1rem;
        text-align: center;
        color: var(--color-black);
        font-size: large;
        font-weight: 300;
        z-index: 2;
        background-color: white;

    }
}

.container-address{
    padding-inline: 1rem;
    position: relative;
}

.radio-address{
    position: absolute;
    &:checked+form{
        background-color: rgb(236, 254, 236);
        border: 1px solid var(--color-light-grey);
    }
}



.checkout-payment div{
    padding: .5rem;
    display: flex;
    flex-direction: column;
}

.checkout-payment .btn-prime{
    background-color: var(--color-black);
    width: 100%;
    margin-inline: 0;
    color: white;
}


.checkout-payment div h4{
    border-bottom: 1px solid var(--color-black);
    margin-block:.5rem ;
}

.checkout-payment div span{
 float: right;
}

.checkout-page>section>div{
    padding: 1rem;
}

@media  (width <= 1224px) {
    .checkout-page{
        flex-direction: column;

        & section{
            margin: auto;
            width: min(600px , 100%);
            height: auto;
        }
    }
}