.wrapper:has(header){
    position: sticky;
    position: -webkit-sticky;
    z-index: 900;
    top: 0;
}

.header-main{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    background-color: var(--color-background-nav);
    border-bottom: 1px solid var(--color-light-grey);
}

header>.logo{
    width: 8rem;
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
    padding: 0.75rem 0;
}

header>nav{
    margin: auto ;
    font-weight: 200;
    text-transform: lowercase;
}

nav li{
    padding: 1rem;
    height: 100%;
}

nav li:hover{
    position: relative;
    padding-bottom: .75rem;
    top: .25rem;
    background-color: var(--color-background-hover);
    border-bottom: .25rem solid var(--color-black);
}

nav li .nav-category-dropdown{
    display: none;
}

nav li:hover .nav-category-dropdown{
    display: flex;
    justify-content: center;
    position: fixed;
    padding-top: .75rem;
    z-index: -1;
    top: 2.75rem;
    left: 0rem;
    width: 100vw;
    height: 100;
    border-bottom: 1px solid var(--color-light-grey);
    background-color: var(--color-background-nav);
    animation: slide-down .3s;
}

@keyframes slide-down {
    from {
        opacity: 0;
      transform: translateY(-20%);
    }
    to {
        opacity: 100%;
      transform: translateX(0%);
    }
  }

.nav-category-card{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: .5rem .25rem;
    padding: .25rem;
    border: 1px solid var(--color-light-black);
}

.nav-category-card img{
    width: 8rem;
    height: 8rem;
    object-fit: contain;
    border: 1px solid var(--color-light-grey);
}

.nav-category-card a:has(img){
    outline: none;
    border: none;
  }

.nav-category-card a{
    text-align: center;
    padding: .25rem 0;
    border: 1px solid var(--color-light-grey);
}

.nav-category-card a:hover{
    background-color: var(--color-background-hover);
}

header .icon{
    width: 2rem;
}

.menu-icon{
    display: none;
}

#pc-nav-img{
    object-fit: cover;
}

  @media (width <= 48rem) {
    
    .header-main{
        padding: 0 .5rem;
    }

    .logo{
        margin: auto;
        text-align: center;
        padding-left: 3rem;
    }

    .search-icon{
        order: -1;
    }

    .menu-icon{
        display: block;
        order: -1;
    }

    .top-bar .user-icon{
        display: none;
    }

    header>nav {
        position: absolute;
        top: 2.75rem;
        padding-top: 1rem;
        background-color: var(--color-background-drawers);
        left: max(-90vw, -18rem);
        max-width: 90vw;
        width: 18rem;
        height: calc(100vh - 2.75rem);
        border-right: 1px solid var(--color-light-grey);
        z-index: -1;
        transition: .5s ease-in-out;
    }

    header>nav.active{
       left: 0;
    }


    nav ul{
        display: flex;
        flex-direction: column;
    }

    nav li{
        border-bottom: 1px solid var(--color-light-grey);
    }
    nav li>*{
        border-bottom: 1px solid #343434;
    }

    nav li:hover{
    position: relative;
    top: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--color-light-grey);
    padding-bottom: 1rem;
    color: blue;
    }

    nav li:hover *{
        background-color: transparent;
        
    }

    nav li:hover .nav-category-dropdown {
        padding-top: 0;
    }

    nav ul .nav-category-card img{
        display: none;
    }

    nav ul .nav-category-dropdown ,nav li:hover .nav-category-dropdown{
        animation: none;
        position: static;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: none;
        background-color: transparent;
        & a:hover{
            background-color: transparent;
            color: blue;
        }
    }

    nav ul .nav-category-card{
        display: block;
        padding: .25rem;
        margin: 0;
        border: none;
        border-bottom: 1px dashed var(--color-light-grey);
        border-left: 1px dashed var(--color-light-grey);
    }

    nav ul .nav-category-card a{
        padding: .25rem 2rem;
        text-align: left;
        margin: 0;
        border: none;
    }

  }

  @media (width <= 20rem){
    .logo{
        font-size: 1rem !important;
        text-align: center;
        vertical-align: middle;
    }
  }